import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa'; // Ensure this import is correct
import './Cards.css'; // CSS for the Cards component

const articles = [
  {
    id: 1,
    title: 'The Rise of Revenue Operations:A Game Changer for Modern Businesses',
    text: 'In today’s rapidly evolving business landscape, one thing is clear: traditional operational silos no longer work.',
    imageUrl: 'https://img.freepik.com/premium-photo/papercut-childhood-dreams-toys-imaginary-friends-joyful-2d-layered-layout_720722-27876.jpg?w=1060', // Add your image path here
    link: '/article/1',
  },
  {
    id: 2,
    title: 'Turning Data into Revenue:The Power of RevOps',
    text: 'We live in the age of data. Every customer interaction, every transaction, and every engagement generates valuable information.',
    imageUrl: 'https://images.unsplash.com/photo-1600675608140-991fcf38cc6e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA==', // Add your image path here
    link: '/article/2',
  },
  {
    id: 3,
    title: 'Automation: The Key to Scaling Revenue Operations',
    text: 'Automation has revolutionized many aspects of business, but its impact on Revenue Operations (RevOps) is particularly profound.',
    imageUrl:require("H:/Renbsha/renbsha-app/src/assets/Article3.png"), // Add your image path here
    link: '/article/3',
  },
  // Add more articles as needed
];

function Cards() {
  return (
    <div className="cards-section">
       
      <Row xs={1} md={2} lg={3} className="g-4">
        {articles.map((article) => (
          <Col key={article.id}>
            <Card className="h-100">
              <Card.Img variant="top" src={article.imageUrl} alt={article.title} />
              <Card.Body>
                <Card.Title>{article.title}</Card.Title>
                <Card.Text>{article.text}</Card.Text>
                <a href={article.link} className="learn-more-link">
                  Learn More <FaArrowRight />
                </a>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Cards;
