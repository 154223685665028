import React, { useState, useEffect} from "react";
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../pages/Firebase"; // Firebase config
import "./Chatbot.css";
import { FaTimes, FaComments } from "react-icons/fa";

function ChatbotWidget() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(localStorage.getItem("chatId") || null);
  const [input, setInput] = useState("");
  const [chatStatus, setChatStatus] = useState("active");
  const [agentName, setAgentName] = useState(null);

  const predefinedQuestions = [
    "Revenue Operations",
    "Services",
    "Capabilities",
    "Connect to an agent",
  ];

  // Start a new chat session or load an existing one
  const startChat = async () => {
    if (!chatId) {
      try {
        const username = `User_${Math.floor(1000 + Math.random() * 9000)}`;

        // Create a new chat document in Firestore
        const docRef = await addDoc(collection(db, "chats"), {
          messages: [],
          status: "pending",
          userId: username,
          agentId: null,
          agentName: null,
          timestamp: new Date().toISOString(),
        });

        setChatId(docRef.id);
        localStorage.setItem("chatId", docRef.id);
        localStorage.setItem("username", username);
        setChatStatus("pending");
      } catch (error) {
        console.error("Error starting chat:", error);
      }
    }
  };

  // Close chat and notify the agent
  const closeChat = async () => {
    if (chatId) {
      try {
        const chatRef = doc(db, "chats", chatId);
        const docSnapshot = await getDoc(chatRef);

        if (docSnapshot.exists()) {
          await updateDoc(chatRef, { status: "closed_by_user" });
          clearChatSession();
        } else {
          console.warn("Chat document does not exist.");
        }
      } catch (error) {
        console.error("Error closing chat:", error);
      }
    }
  };

  // Clear chat session and reset state
  const clearChatSession = () => {
    setChatId(null);
    setMessages([]);
    setChatStatus("resolved");
    setAgentName(null);
    localStorage.removeItem("chatId");
    localStorage.removeItem("username");
  };

  // Real-time chat updates
  useEffect(() => {
    if (chatId) {
      const chatRef = doc(db, "chats", chatId);
      const startChat = async () => {
        if (!chatId) {
          try {
            const username = `User_${Math.floor(1000 + Math.random() * 9000)}`;
            const docRef = await addDoc(collection(db, "chats"), {
              messages: [],
              status: "pending",
              userId: username,
              agentId: null,
              agentName: null,
              timestamp: new Date().toISOString(),
            });
            setChatId(docRef.id);
            localStorage.setItem("chatId", docRef.id);
            localStorage.setItem("username", username);
            setChatStatus("pending");
          } catch (error) {
            console.error("Error starting chat:", error);
          }
        }
      };

      const unsubscribe = onSnapshot(chatRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setMessages(data.messages);
          setChatStatus(data.status);

          // Update agentName if connected
          if (data.status === "connected" && data.agentName) {
            setAgentName(data.agentName);
          }

          // Notify user if the chat is closed
          if (data.status === "resolved" || data.status === "closed_by_user") {
            clearChatSession();
          }

          // Handle agent-closed chat
          if (data.status === "closed_by_agent") {
            setMessages((prev) => [
              ...prev,
              {
                sender: "bot",
                text: "The chat has been closed by the agent. Thank you for chatting with us!",
                timestamp: new Date().toISOString(),
              },
            ]);
            clearChatSession();
          }
        } else {
          console.warn("Chat document does not exist. Starting a new chat.");
          clearChatSession();
          startChat();
        }
      });
      if (!chatId) startChat();

      return () => unsubscribe();
    }
  }, [chatId]);

  // Send a message
  const handleSendMessage = async (message) => {
    if (!chatId) return;

    const chatRef = doc(db, "chats", chatId);
    const userMessage = { sender: "user", text: message, timestamp: new Date().toISOString() };
    let updatedMessages = [...messages, userMessage];

    try {
      if (chatStatus === "connected") {
        await updateDoc(chatRef, { messages: updatedMessages });
        setMessages(updatedMessages);
        return;
      }

      if (message === "Connect to an agent") {
        await updateDoc(chatRef, { status: "pending" });
        updatedMessages = [
          ...updatedMessages,
          {
            sender: "bot",
            text: "Connecting you to an agent. Please wait...",
            timestamp: new Date().toISOString(),
          },
        ];
      } else {
        updatedMessages = [
          ...updatedMessages,
          {
            sender: "bot",
            text: getBotResponse(message),
            timestamp: new Date().toISOString(),
          },
        ];
      }

      await updateDoc(chatRef, { messages: updatedMessages });
      setMessages(updatedMessages);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Predefined bot responses
  const getBotResponse = (message) => {
    switch (message) {
      case "Revenue Operations":
        return "Revenue Operations (RevOps) aligns sales, marketing, and customer success teams.";
      case "Services":
        return "RevOps helps streamline operations and improve revenue growth.";
      case "Capabilities":
        return "RenBSha provides RevOps consulting, strategy development, and process optimization.";
      default:
        return "Let me connect you to an agent.";
    }
  };

  // Handle predefined question click
  const handlePredefinedQuestion = (question) => {
    handleSendMessage(question);
  };

  // Handle user input submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      handleSendMessage(input.trim());
      setInput("");
    }
  };

  return (
    <div className="chatbot-widget">
      <button
        onClick={() => {
          setIsOpen(!isOpen);
          if (!chatId) startChat();
        }}
        className="chatbot-toggle"
      >
        {isOpen ? <FaTimes /> : <FaComments />}
      </button>
      {isOpen && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <h6>Chat with {agentName ? agentName : "RenBSha"}</h6>
            <button onClick={closeChat} className="close-chat-btn">
              Close Chat
            </button>
          </div>
          <div className="chatbot-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`message-bubble ${msg.sender === "user" ? "user" : "bot"}`}
              >
                {msg.text}
              </div>
            ))}
            {chatStatus === "resolved" && (
              <div className="chatbot-closed-message">
                The chat has been closed. Thank you for chatting with us!
              </div>
            )}
          </div>
          {chatStatus !== "connected" && chatStatus !== "resolved" && (
            <div className="predefined-questions">
              {predefinedQuestions.map((question, index) => (
                <button
                  key={index}
                  className="predefined-question"
                  onClick={() => handlePredefinedQuestion(question)}
                >
                  {question}
                </button>
              ))}
            </div>
          )}
          {chatStatus !== "resolved" && (
            <form onSubmit={handleSubmit} className="chatbot-footer">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={
                  chatStatus === "connected" ? "Message the agent..." : "Type your question..."
                }
              />
              <button type="submit">Send</button>
            </form>
          )}
        </div>
      )}
    </div>
  );
}

export default ChatbotWidget;
