import React from "react";
import { Link } from "react-router-dom";
import "./Insights.css";

function Insights() {
  const articles = [
    {
        id: 1,
        title: "The Rise of Revenue Operations: A Game Changer for Modern Businesses",
        image: "https://img.stockanalysis.com/c2701e56-d6b2-440b-9034-03776db5d672/revenue-growth-concept.jpg",
        date: "November 20, 2024",
        category: "Article",
        excerpt: "In today’s rapidly evolving business landscape, RevOps transforms revenue growth by aligning teams and streamlining operations.",
      },
      {
        id: 2,
        title: "Turning Data into Revenue: The Power of RevOps",
        image: "https://altline.sobanco.com/wp-content/uploads/2023/04/revenue.jpg",
        date: "October 1, 2024",
        category: "Article",
        excerpt: "RevOps helps businesses harness data to fuel growth, improve decision-making, and create more personalized customer experiences.",
      },
      {
        id: 3,
        title: "Automation: The Key to Scaling Revenue Operations",
        image: require("H:/Renbsha/renbsha-app/src/assets/Article3.png"),
        date: "September 18, 2024",
        category: "Article",
        excerpt: "Automation within RevOps ensures workflows are efficient, error-free, and scalable for growth.",
      },
      
  ];

  return (
    <div>
      <section className="heroer-section">
        <h1>Featured Insights</h1>
      </section>

      <div className="articles-container">
      {articles.map((article) => (
        <div key={article.id} className="article-card">
          <img src={article.image} alt={article.title} />
          <div className="content">
            <p className="meta">{`${article.category} - ${article.date}`}</p>
            <h3>{article.title}</h3>
            <p>{article.excerpt}</p>
            <Link to={`/article/${article.id}`}>Read More</Link>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
}

export default Insights;
